<template>
  <div
    v-loading="loading"
    element-loading-text="拼命加载中..."
    element-loading-spinner="el-icon-loading"
    class="wx-app-detail"
  >
    <div
      v-if="(appConfigId != null && appConfigId > 0) || this.action === 'add'"
      class="not-empty-div"
    >
      <div class="edit-div">
        <p class="title">{{ this.action === "add" ? "新增" : "编辑" }}</p>
        <el-form
          ref="appForm"
          :model="app"
          label-suffix=":"
          size="small"
          :rules="appFormRules"
          label-position="right"
          label-width="180px"
          class="wx-app-detail-form"
        >
          <el-form-item label="应用编码" prop="appCode">
            <div>
              {{ action === "edit" ? app.appCode : "新增成功后自动生成" }}
            </div>
          </el-form-item>
          <el-form-item label="名称" prop="appName">
            <el-input v-model="app.appName" />
          </el-form-item>
          <el-form-item label="开发者ID(AppID)" prop="appId">
            <el-input v-model="app.appId" :disabled="action === 'edit'" />
          </el-form-item>
          <el-form-item
            label="开发者密码(AppSecret)"
            prop="appSecret"
            :required="action === 'add'"
          >
            <el-input
              v-model="app.appSecret"
              type="text"
              style="width: 70%; margin-right: 1rem"
            />

            <el-tooltip
              effect="dark"
              content="系统将记录此行为"
              placement="bottom"
            >
              <el-button
                v-if="appConfigId != null && appConfigId > 0"
                type="text"
                @dblclick.native="showAppSecret"
                >双击显示开发者密码</el-button
              >
            </el-tooltip>
          </el-form-item>
          <el-form-item label="应用类型" prop="appType">
            <el-radio-group v-model="app.appType">
              <el-radio :label="1">订阅号</el-radio>
              <el-radio :label="2">服务号</el-radio>
              <el-radio :label="3">小程序</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item label="Token" prop="token">
            <el-input v-model="app.token" />
          </el-form-item>
          <el-form-item label="消息加密方式" prop="msgEncodingMode">
            <el-radio-group v-model="app.msgEncodingMode">
              <el-radio :label="0">明文模式</el-radio>
              <el-radio :label="1">加密模式</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item
            label="消息加密密钥"
            prop="encodingAesKey"
            :required="this.app.msgEncodingMode == 1"
          >
            <el-input v-model="app.encodingAesKey" />
          </el-form-item>
          <el-form-item label="微信认证" prop="authFlag">
            <el-radio-group v-model="app.authFlag">
              <el-radio :label="0">未认证</el-radio>
              <el-radio :label="1">已认证</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item
            label="认证通过时间"
            prop="authTime"
            :required="this.app.authFlag == 1"
          >
            <el-date-picker
              v-model="app.authTime"
              type="datetime"
              value-format="yyyy-MM-dd HH:mm:ss"
              placeholder="选择日期时间"
            >
            </el-date-picker>
          </el-form-item>
          <el-form-item>
            <el-button
              v-power="`40-10-20 || 40-10-10`"
              type="primary"
              :loading="btnLoading.save"
              @click="doSave"
              :disabled="!changed"
              >保存</el-button
            >
            <el-button
              v-power="`40-10-30`"
              type="danger"
              :loading="btnLoading.disable"
              @click="doDisable"
              v-if="app.id > 0 && app.flag == 1"
              >停用</el-button
            >
            <el-button
              v-power="`40-10-40`"
              type="success"
              :loading="btnLoading.enable"
              @click="doEnable"
              v-if="app.id > 0 && app.flag == 0"
              >启用</el-button
            >
            <el-button
              v-power="`40-10-50`"
              type="warning"
              :loading="btnLoading.setDefault"
              @click="doSetDefault"
              v-if="app.defaultFlag == 0">
              设为默认
            </el-button>
          </el-form-item>
        </el-form>
      </div>
      <div class="config-desc">
        <wx-app-tb-rel-config v-if="app.id > 0" :app-config-id="app.id" />

        <wx-app-config-desc :app-config-id="app.id"></wx-app-config-desc>
      </div>
    </div>
    <div v-else class="empty-div">
      <el-empty description="选择一个应用看看吧"></el-empty>
    </div>
  </div>
</template>

<script>
export default {
  name: "wx-app-detail",
  props: {
    appConfigId: {
      type: Number,
      required: false,
    },
  },
  data() {
    let encodingAesKeyValidator = (rule, value, callback) => {
      if (this.app.msgEncodingMode == 1 && !value) {
        callback(new Error("加密模式下，消息加密密钥必填"));
      } else {
        callback();
      }
    };

    let authTimeValidator = (rule, value, callback) => {
      if (this.app.authFlag == 1 && !value) {
        callback(new Error("已认证的应用，必须填写认证通过时间"));
      } else {
        callback();
      }
    };

    let appSecretValidator = (rule, value, callback) => {
      if (this.action == "add" && !value) {
        callback(new Error("新增应用时appSecret必填"));
      } else {
        callback();
      }
    };

    return {
      loading: true,
      btnLoading: {
        save: false,
        enable: false,
        disable: false,
        setDefault: false
      },
      app: {},
      changed: false,
      action: "edit",
      appFormRules: {
        appName: [{ required: true, message: "应用名称必填", tigger: "blur" }],
        appId: [{ required: true, message: "appId必填", tigger: "blur" }],
        appSecret: [{ validator: appSecretValidator, tigger: "blur" }],
        encodingAesKey: [
          { validator: encodingAesKeyValidator, tigger: "blur" },
        ],
        authTime: [{ validator: authTimeValidator, tigger: "blur" }],
      },
    };
  },
  methods: {
    async doSetDefault() {
      this.btnLoading.setDefault = true;
      try {
         await this.$http.doApi('fw-wx-app-set-default', {id: this.appConfigId});
         this.$emit('changed', this.appConfigId);
         this.app.defaultFlag = 1;
         this.$message.success('设置成功');
      } catch(e) {
        console.log(e);
      } finally {
        this.btnLoading.setDefault = false;
      }
    },
    async doDisable() {
      try {
        await this.$confirm(
          "停用后该应用将立即失效，且不再接收任何消息处理，是否继续？",
          "警告",
          {
            confirmButtonText: "继续",
            cancelButtonText: "取消",
            type: "warning",
          }
        );
      } catch (e) {
        console.log(e);
        return;
      }

      this.btnLoading.disable = true;
      try {
        await this.$http.doApi("fw-wx-app-disable", { id: this.appConfigId });
        this.$emit("changed", this.appConfigId);
        this.app.flag = 0;
      } catch (e) {
        console.log(e);
      } finally {
        this.btnLoading.disable = false;
      }
    },
    async doEnable() {
      this.btnLoading.enable = true;
      try {
        await this.$http.doApi("fw-wx-app-enable", { id: this.appConfigId });
        this.$emit("changed", this.appConfigId);
        this.app.flag = 1;
      } catch (e) {
        console.log(e);
      } finally {
        this.btnLoading.enable = false;
      }
    },
    doAdd() {
      this.action = "add";
    },
    showAppSecret() {
      this.$http.doApi("fw-wx-app-getAppSecret", {
        id: this.appConfigId,
      }).then(data => {
        // 直接赋值会导致视图不更新
        this.$set(this.app, 'appSecret', data.val);
      });
    },
    async doSave() {
      try {
        await this.$refs.appForm.validate();
      } catch (e) {
        console.log(e);
        return;
      }

      let errorFlag = false;
      try {
        let res = await this.$http.doApi("fw-wx-app-save", {}, this.app);
        this.app.id = res.val;
        this.$message.success("保存成功");
      } catch (e) {
        console.log(e);
        errorFlag = true;
      } finally {
        if (!errorFlag) {
          this.$emit("changed", this.app.id);
        }
      }
    },
    async refreshAppInfo(id) {
      if (id == 0) {
        this.app = {};
        this.$nextTick(() => {
          this.$refs.appForm.clearValidate();
          this.changed = false;
        });
        return;
      }
      this.loading = true;
      try {
        let data = await this.$http.doApi("fw-wx-app-get", {
          id,
          wrapper: true,
        });
        this.app = data;
        this.$nextTick(() => {
          this.$refs.appForm.clearValidate();
          this.changed = false;
        });
      } catch (e) {
        console.log(e);
      } finally {
        this.loading = false;
      }
    },
  },
  created() {
    if (this.appConfigId > 0) {
      this.refreshAppInfo(this.appConfigId);
    } else {
      this.loading = false;
    }
  },
  watch: {
    appConfigId(val) {
      if (val > 0) {
        this.action = "edit";
      }
      this.refreshAppInfo(val);
    },
    app: {
      handler() {
        this.changed = true;
      },
      deep: true,
    },
  },
  components: {
    "wx-app-config-desc": () => import("./WxAppConfigDesc"),
    "wx-app-tb-rel-config": () => import("./WxAppTbRelConfig"),
  },
};
</script>

<style lang="less">
.wx-app-detail {
  height: 100%;

  .not-empty-div {
    height: 100%;
    display: flex;
    .title {
      margin: 0;
      border-bottom: 2px dotted #cccccc;
      margin-bottom: 1rem;
      padding-bottom: 0.4rem;
      margin-right: 1rem;
    }

    .edit-div {
      flex: 5;
      padding-right: 1rem;
      border-right: 1px solid #cccccc;
    }

    .config-desc {
      height: 100%;
      flex: 4;
      padding-left: 1rem;
    }
  }

  .empty-div {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
  }
}
</style>